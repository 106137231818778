import React from "react";
import Layout from "../../components/layout"

import Radek from "../../images/radek.png";
import TitleHeader from "../../components/TitleHeader";
import GalleryComponent from "../../components/GalleryComponent"
import cert1 from "../../images/certyfikaty_radek/1.jpg"
import cert2 from "../../images/certyfikaty_radek/2.jpg"
import cert3 from "../../images/certyfikaty_radek/3.jpg"
import cert4 from "../../images/certyfikaty_radek/4.jpg"
import cert5 from "../../images/certyfikaty_radek/5.jpg"
import cert6 from "../../images/certyfikaty_radek/6.jpg"
import cert7 from "../../images/certyfikaty_radek/7.jpg"
import cert8 from "../../images/certyfikaty_radek/8.jpg"
import cert9 from "../../images/certyfikaty_radek/9.jpg"
import cert10 from "../../images/certyfikaty_radek/10.jpg"
import cert11 from "../../images/certyfikaty_radek/11.jpg"
import cert12 from "../../images/certyfikaty_radek/12.jpg"
import cert13 from "../../images/certyfikaty_radek/13.jpg"
import cert14 from "../../images/certyfikaty_radek/14.jpg"
import cert15 from "../../images/certyfikaty_radek/15.jpg"
import cert16 from "../../images/certyfikaty_radek/16.jpg"
import cert17 from "../../images/certyfikaty_radek/17.jpg"
import cert18 from "../../images/certyfikaty_radek/18.jpg"
import cert19 from "../../images/certyfikaty_radek/19.jpg"
import cert20 from "../../images/certyfikaty_radek/20.jpg"
import cert21 from "../../images/certyfikaty_radek/21.jpg"


const RadoslawLysek = () => {
  // render(){
  const handleClick = () => {
    console.log("click");
    window.medicall_modal("336fbc3e6b050a0c3be5");
    return false;
  };

  const certification = [
    cert1,cert2,cert3,cert4,cert5,cert6,cert7,cert8,cert9,cert10,cert11,cert12,cert13,cert14,cert15,cert16,cert17,cert18,cert19,cert20,cert21
  ];
  // onclick="medicall_modal('336fbc3e6b050a0c3be5');return false;"
  return (
    <Layout>
      <TitleHeader title="dr n. med. Radosław Łysek" />
      <div className="content zespol-template">
        <div className="img-contener">
          <img src={Radek} className="img" alt="Radek" />
        </div>

        <div className="text-contener">
          <h1>dr n. med. Radosław Łysek</h1>
          <p className="under-h1">lekarz dentysta</p>
          <img
            src="https://sztukastomatologii.pl/wp-content/uploads/2018/07/zapraszam-300x89.png"
            alt="podpis"
            className="img podpis"
          />
          <p>
            Profesjonalista w dziedzinie stomatologii estetycznej oraz
            implanto-protetyki. Zajmuje się kompleksowym planowaniem leczenia z
            wykorzystaniem zawansowanych technologii oraz m.in. technik
            wizualnych. Koordynuje pracę specjalistów czuwając nad harmonogramem
            oraz kosztorysem. Twórca oraz właściciel Dental Arts Studio, które
            jest wyrazem wieloletnich przemyśleń na temat organizacji
            nowoczesnej opieki stomatologicznej. Ukończył wydział lekarski
            Collegium Medicum Uniwersytetu Jagiellońskiego gdzie obronił również
            pracę doktorską. Specjalista Zdrowia Publicznego. Uczestnik licznych
            kursów, których tematyka obejmuje minimalnie inwazyjne techniki
            pracy, odbudowy pełnoceramiczne, cyfrowe projektowanie uśmiechu,
            kompleksowe planowanie leczenia. Biegle włada językiem angielskim.
            Prywatnie miłośnik podróży oraz inwestycji giełdowych.
          </p>
          <button class="btn btn-register" type="button" onClick={handleClick}>
            Zarejestruj się
          </button>
        </div>
      </div>
      <GalleryComponent className="content" img={certification} />
    </Layout>
  );
};
// }
export default RadoslawLysek;
